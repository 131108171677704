/* tslint:disable */
/* eslint-disable */
/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 10.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OdysFeedback,
    OdysFeedbackFromJSON,
    OdysFeedbackToJSON,
} from '../models';

export interface FeedbackDeleteRequest {
    id?: string;
    text?: string;
    roomId?: string;
    createdAt?: string;
    updatedAt?: string;
    prefer?: FeedbackDeletePreferEnum;
}

export interface FeedbackGetRequest {
    id?: string;
    text?: string;
    roomId?: string;
    createdAt?: string;
    updatedAt?: string;
    select?: string;
    order?: string;
    range?: string;
    rangeUnit?: string;
    offset?: string;
    limit?: string;
    prefer?: FeedbackGetPreferEnum;
}

export interface FeedbackPatchRequest {
    id?: string;
    text?: string;
    roomId?: string;
    createdAt?: string;
    updatedAt?: string;
    prefer?: FeedbackPatchPreferEnum;
    feedback?: OdysFeedback;
}

export interface FeedbackPostRequest {
    select?: string;
    prefer?: FeedbackPostPreferEnum;
    feedback?: OdysFeedback;
}

/**
 * 
 */
export class FeedbackApi extends runtime.BaseAPI {

    /**
     * Feedback about the app
     */
    async feedbackDeleteRaw(requestParameters: FeedbackDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/feedback`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Feedback about the app
     */
    async feedbackDelete(requestParameters: FeedbackDeleteRequest): Promise<void> {
        await this.feedbackDeleteRaw(requestParameters);
    }

    /**
     * Feedback about the app
     */
    async feedbackGetRaw(requestParameters: FeedbackGetRequest): Promise<runtime.ApiResponse<Array<OdysFeedback>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.range !== undefined && requestParameters.range !== null) {
            headerParameters['Range'] = String(requestParameters.range);
        }

        if (requestParameters.rangeUnit !== undefined && requestParameters.rangeUnit !== null) {
            headerParameters['Range-Unit'] = String(requestParameters.rangeUnit);
        }

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OdysFeedbackFromJSON));
    }

    /**
     * Feedback about the app
     */
    async feedbackGet(requestParameters: FeedbackGetRequest): Promise<Array<OdysFeedback>> {
        const response = await this.feedbackGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Feedback about the app
     */
    async feedbackPatchRaw(requestParameters: FeedbackPatchRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/feedback`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OdysFeedbackToJSON(requestParameters.feedback),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Feedback about the app
     */
    async feedbackPatch(requestParameters: FeedbackPatchRequest): Promise<void> {
        await this.feedbackPatchRaw(requestParameters);
    }

    /**
     * Feedback about the app
     */
    async feedbackPostRaw(requestParameters: FeedbackPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OdysFeedbackToJSON(requestParameters.feedback),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Feedback about the app
     */
    async feedbackPost(requestParameters: FeedbackPostRequest): Promise<void> {
        await this.feedbackPostRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum FeedbackDeletePreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum FeedbackGetPreferEnum {
    Countnone = 'count=none'
}
/**
    * @export
    * @enum {string}
    */
export enum FeedbackPatchPreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum FeedbackPostPreferEnum {
    Returnrepresentation = 'return=representation',
    Returnminimal = 'return=minimal',
    Returnnone = 'return=none',
    ResolutionignoreDuplicates = 'resolution=ignore-duplicates',
    ResolutionmergeDuplicates = 'resolution=merge-duplicates'
}
