/* tslint:disable */
/* eslint-disable */
/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 10.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OdysBoard,
    OdysBoardFromJSON,
    OdysBoardToJSON,
} from '../models';

export interface BoardDeleteRequest {
    id?: string;
    roomId?: string;
    createdAt?: string;
    updatedAt?: string;
    prefer?: BoardDeletePreferEnum;
}

export interface BoardGetRequest {
    id?: string;
    roomId?: string;
    createdAt?: string;
    updatedAt?: string;
    select?: string;
    order?: string;
    range?: string;
    rangeUnit?: string;
    offset?: string;
    limit?: string;
    prefer?: BoardGetPreferEnum;
}

export interface BoardPatchRequest {
    id?: string;
    roomId?: string;
    createdAt?: string;
    updatedAt?: string;
    prefer?: BoardPatchPreferEnum;
    board?: OdysBoard;
}

export interface BoardPostRequest {
    select?: string;
    prefer?: BoardPostPreferEnum;
    board?: OdysBoard;
}

/**
 * 
 */
export class BoardApi extends runtime.BaseAPI {

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardDeleteRaw(requestParameters: BoardDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/board`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardDelete(requestParameters: BoardDeleteRequest): Promise<void> {
        await this.boardDeleteRaw(requestParameters);
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardGetRaw(requestParameters: BoardGetRequest): Promise<runtime.ApiResponse<Array<OdysBoard>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.range !== undefined && requestParameters.range !== null) {
            headerParameters['Range'] = String(requestParameters.range);
        }

        if (requestParameters.rangeUnit !== undefined && requestParameters.rangeUnit !== null) {
            headerParameters['Range-Unit'] = String(requestParameters.rangeUnit);
        }

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/board`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OdysBoardFromJSON));
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardGet(requestParameters: BoardGetRequest): Promise<Array<OdysBoard>> {
        const response = await this.boardGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardPatchRaw(requestParameters: BoardPatchRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/board`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OdysBoardToJSON(requestParameters.board),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardPatch(requestParameters: BoardPatchRequest): Promise<void> {
        await this.boardPatchRaw(requestParameters);
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardPostRaw(requestParameters: BoardPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/board`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OdysBoardToJSON(requestParameters.board),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A board can be drawn on. Belongs to a room
     */
    async boardPost(requestParameters: BoardPostRequest): Promise<void> {
        await this.boardPostRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum BoardDeletePreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum BoardGetPreferEnum {
    Countnone = 'count=none'
}
/**
    * @export
    * @enum {string}
    */
export enum BoardPatchPreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum BoardPostPreferEnum {
    Returnrepresentation = 'return=representation',
    Returnminimal = 'return=minimal',
    Returnnone = 'return=none',
    ResolutionignoreDuplicates = 'resolution=ignore-duplicates',
    ResolutionmergeDuplicates = 'resolution=merge-duplicates'
}
