/* tslint:disable */
/* eslint-disable */
/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 10.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OdysRoom,
    OdysRoomFromJSON,
    OdysRoomToJSON,
} from '../models';

export interface RoomDeleteRequest {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    prefer?: RoomDeletePreferEnum;
}

export interface RoomGetRequest {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    select?: string;
    order?: string;
    range?: string;
    rangeUnit?: string;
    offset?: string;
    limit?: string;
    prefer?: RoomGetPreferEnum;
}

export interface RoomPatchRequest {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    prefer?: RoomPatchPreferEnum;
    room?: OdysRoom;
}

export interface RoomPostRequest {
    select?: string;
    prefer?: RoomPostPreferEnum;
    room?: OdysRoom;
}

/**
 * 
 */
export class RoomApi extends runtime.BaseAPI {

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomDeleteRaw(requestParameters: RoomDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomDelete(requestParameters: RoomDeleteRequest): Promise<void> {
        await this.roomDeleteRaw(requestParameters);
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomGetRaw(requestParameters: RoomGetRequest): Promise<runtime.ApiResponse<Array<OdysRoom>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.range !== undefined && requestParameters.range !== null) {
            headerParameters['Range'] = String(requestParameters.range);
        }

        if (requestParameters.rangeUnit !== undefined && requestParameters.rangeUnit !== null) {
            headerParameters['Range-Unit'] = String(requestParameters.rangeUnit);
        }

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OdysRoomFromJSON));
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomGet(requestParameters: RoomGetRequest): Promise<Array<OdysRoom>> {
        const response = await this.roomGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomPatchRaw(requestParameters: RoomPatchRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OdysRoomToJSON(requestParameters.room),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomPatch(requestParameters: RoomPatchRequest): Promise<void> {
        await this.roomPatchRaw(requestParameters);
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomPostRaw(requestParameters: RoomPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OdysRoomToJSON(requestParameters.room),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User\'s gather in a room to draw diagrams in real-time.
     */
    async roomPost(requestParameters: RoomPostRequest): Promise<void> {
        await this.roomPostRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum RoomDeletePreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum RoomGetPreferEnum {
    Countnone = 'count=none'
}
/**
    * @export
    * @enum {string}
    */
export enum RoomPatchPreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum RoomPostPreferEnum {
    Returnrepresentation = 'return=representation',
    Returnminimal = 'return=minimal',
    Returnnone = 'return=none',
    ResolutionignoreDuplicates = 'resolution=ignore-duplicates',
    ResolutionmergeDuplicates = 'resolution=merge-duplicates'
}
