import React from 'react';
import { useSelector } from 'global/redux';
import { shallowEqual } from 'react-redux';

const Cursors: React.FC = () => {
  const players = useSelector((s) => s.players.players, shallowEqual);
  const playerCursors = useSelector(
    (s) => Object.values(s.players.cursors),
    shallowEqual
  );

  function renderCursors() {
    return playerCursors.map((pc) => {
      const player = players[pc.playerId];
      return (
        <use
          style={{ pointerEvents: 'none' }}
          key={player.id}
          href="#cursor"
          fill={player.color}
          width="22px"
          height="22px"
          x={pc.cursor.x}
          y={pc.cursor.y}
        ></use>
      );
    });
  }

  return (
    <>
      <defs>
        {/*<symbol id="cursor" height="511pt" viewBox="-95 0 511 511.6402" width="511pt">
          <path d="m315.710938 292.25-288.894532-288.792969c-10.582031-8.402343-26.496094-.382812-26.496094 12.183594v394.667969c0 8.832031 7.167969 16 16 16 4.160157 0 8.148438-1.601563 10.433594-3.90625l80.039063-69.738282 65.28125 152.511719c1.109375 2.601563 3.199219 4.652344 5.824219 5.71875 1.28125.488281 2.625.746094 3.96875.746094 1.429687 0 2.859374-.300781 4.203124-.875l68.691407-29.441406c5.417969-2.300781 7.9375-8.574219 5.613281-13.992188l-63.191406-147.691406h107.136718c8.832032 0 16-7.167969 16-16 0-2.582031-.660156-6.464844-4.609374-11.390625zm0 0"/>
        </symbol>*/}
        {/*<symbol id="cursor" x="0px" y="0px" viewBox="0 0 512 512" width="512" height="512">
          <g>
            <path fill="black" d="M507.606,422.754l-86.508-86.508l42.427-42.426c3.676-3.676,5.187-8.993,3.992-14.053s-4.923-9.14-9.855-10.784   L203.104,184.13c-5.389-1.797-11.333-0.394-15.35,3.624c-4.018,4.018-5.421,9.96-3.624,15.35l84.853,254.559   c1.645,4.932,5.725,8.661,10.784,9.855c5.059,1.197,10.377-0.315,14.053-3.992l42.427-42.426l86.508,86.507   c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394l63.64-63.64C513.465,438.109,513.465,428.612,507.606,422.754z    M433.36,475.787l-86.508-86.507c-5.857-5.858-15.356-5.858-21.213,0l-35.871,35.871l-67.691-203.073l203.073,67.691   l-35.871,35.871c-5.853,5.852-5.858,15.356,0,21.213l86.508,86.508L433.36,475.787z"/>
            <path d="M195,120c8.284,0,15-6.716,15-15V15c0-8.284-6.716-15-15-15s-15,6.716-15,15v90C180,113.284,186.716,120,195,120z"/>
            <path d="M78.327,57.114c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l63.64,63.64   c5.857,5.858,15.356,5.858,21.213,0c5.858-5.858,5.858-15.355,0-21.213L78.327,57.114z"/>
            <path d="M120.754,248.033l-63.64,63.64c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.858,15.356,5.858,21.213,0l63.64-63.64   c5.858-5.858,5.858-15.355,0-21.213C136.109,242.175,126.611,242.175,120.754,248.033z"/>
            <path d="M269.246,141.967l63.64-63.64c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0l-63.64,63.64   c-5.858,5.858-5.858,15.355,0,21.213C253.89,147.825,263.389,147.825,269.246,141.967z"/>
            <path d="M120,195c0-8.284-6.716-15-15-15H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h90C113.284,210,120,203.284,120,195z"/>
          </g>
        </symbol>*/}
        <symbol
          id="cursor"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width="512"
          height="512"
        >
          <g>
            <g fill="black">
              <path d="M422.762,507.605l-87.518-87.503l-42.422,42.422c-3.662,3.662-8.979,5.171-14.048,3.999    c-5.068-1.201-9.141-4.922-10.781-9.858l-84.858-254.561c-1.802-5.391-0.396-11.338,3.618-15.352    c3.999-3.999,9.961-5.464,15.352-3.618l254.561,84.858c4.937,1.641,8.657,5.713,9.858,10.781    c1.187,5.054-0.322,10.371-3.999,14.048l-42.422,42.422l87.503,87.517c5.859,5.859,5.859,15.352,0,21.211l-63.633,63.633    C438.113,513.465,428.621,513.465,422.762,507.605z" />
            </g>
            <g>
              <path d="M195,120c-8.291,0-15-6.709-15-15V15c0-8.291,6.709-15,15-15s15,6.709,15,15v90C210,113.291,203.291,120,195,120z" />
            </g>
            <g>
              <path d="M120.762,141.973L57.114,78.325c-5.859-5.859-5.859-15.352,0-21.211s15.352-5.859,21.211,0l63.647,63.647    c5.859,5.859,5.859,15.352,0,21.211C136.113,147.832,126.621,147.832,120.762,141.973z" />
            </g>
            <g>
              <path d="M57.114,332.886c-5.859-5.859-5.859-15.352,0-21.211l63.647-63.647c5.859-5.859,15.352-5.859,21.211,0    s5.859,15.352,0,21.211l-63.647,63.647C72.466,338.745,62.974,338.745,57.114,332.886z" />
            </g>
            <g>
              <path d="M248.027,141.973c-5.859-5.859-5.859-15.352,0-21.211l63.647-63.647c5.859-5.859,15.352-5.859,21.211,0    s5.859,15.352,0,21.211l-63.647,63.647C263.379,147.832,253.887,147.832,248.027,141.973z" />
            </g>
            <g>
              <path d="M105,210H15c-8.291,0-15-6.709-15-15s6.709-15,15-15h90c8.291,0,15,6.709,15,15S113.291,210,105,210z" />
            </g>
          </g>
        </symbol>
      </defs>
      {renderCursors()}
    </>
  );
};
export default Cursors;
