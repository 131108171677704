/* tslint:disable */
/* eslint-disable */
/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 10.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OdysRoomUser,
    OdysRoomUserFromJSON,
    OdysRoomUserToJSON,
} from '../models';

export interface RoomUserDeleteRequest {
    roomId?: string;
    userId?: string;
    isOnline?: boolean;
    createdAt?: string;
    updatedAt?: string;
    prefer?: RoomUserDeletePreferEnum;
}

export interface RoomUserGetRequest {
    roomId?: string;
    userId?: string;
    isOnline?: boolean;
    createdAt?: string;
    updatedAt?: string;
    select?: string;
    order?: string;
    range?: string;
    rangeUnit?: string;
    offset?: string;
    limit?: string;
    prefer?: RoomUserGetPreferEnum;
}

export interface RoomUserPatchRequest {
    roomId?: string;
    userId?: string;
    isOnline?: boolean;
    createdAt?: string;
    updatedAt?: string;
    prefer?: RoomUserPatchPreferEnum;
    roomUser?: OdysRoomUser;
}

export interface RoomUserPostRequest {
    select?: string;
    prefer?: RoomUserPostPreferEnum;
    roomUser?: OdysRoomUser;
}

/**
 * 
 */
export class RoomUserApi extends runtime.BaseAPI {

    /**
     */
    async roomUserDeleteRaw(requestParameters: RoomUserDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.isOnline !== undefined) {
            queryParameters['is_online'] = requestParameters.isOnline;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room_user`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roomUserDelete(requestParameters: RoomUserDeleteRequest): Promise<void> {
        await this.roomUserDeleteRaw(requestParameters);
    }

    /**
     */
    async roomUserGetRaw(requestParameters: RoomUserGetRequest): Promise<runtime.ApiResponse<Array<OdysRoomUser>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.isOnline !== undefined) {
            queryParameters['is_online'] = requestParameters.isOnline;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.range !== undefined && requestParameters.range !== null) {
            headerParameters['Range'] = String(requestParameters.range);
        }

        if (requestParameters.rangeUnit !== undefined && requestParameters.rangeUnit !== null) {
            headerParameters['Range-Unit'] = String(requestParameters.rangeUnit);
        }

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OdysRoomUserFromJSON));
    }

    /**
     */
    async roomUserGet(requestParameters: RoomUserGetRequest): Promise<Array<OdysRoomUser>> {
        const response = await this.roomUserGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async roomUserPatchRaw(requestParameters: RoomUserPatchRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.roomId !== undefined) {
            queryParameters['room_id'] = requestParameters.roomId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        if (requestParameters.isOnline !== undefined) {
            queryParameters['is_online'] = requestParameters.isOnline;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room_user`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OdysRoomUserToJSON(requestParameters.roomUser),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roomUserPatch(requestParameters: RoomUserPatchRequest): Promise<void> {
        await this.roomUserPatchRaw(requestParameters);
    }

    /**
     */
    async roomUserPostRaw(requestParameters: RoomUserPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/room_user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OdysRoomUserToJSON(requestParameters.roomUser),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roomUserPost(requestParameters: RoomUserPostRequest): Promise<void> {
        await this.roomUserPostRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum RoomUserDeletePreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum RoomUserGetPreferEnum {
    Countnone = 'count=none'
}
/**
    * @export
    * @enum {string}
    */
export enum RoomUserPatchPreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum RoomUserPostPreferEnum {
    Returnrepresentation = 'return=representation',
    Returnminimal = 'return=minimal',
    Returnnone = 'return=none',
    ResolutionignoreDuplicates = 'resolution=ignore-duplicates',
    ResolutionmergeDuplicates = 'resolution=merge-duplicates'
}
