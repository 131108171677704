/* tslint:disable */
/* eslint-disable */
/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 10.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OdysArrow,
    OdysArrowFromJSON,
    OdysArrowToJSON,
} from '../models';

export interface ArrowDeleteRequest {
    id?: string;
    boardId?: string;
    fromShapeId?: string;
    toShapeId?: string;
    createdAt?: string;
    updatedAt?: string;
    text?: string;
    isDeleted?: boolean;
    prefer?: ArrowDeletePreferEnum;
}

export interface ArrowGetRequest {
    id?: string;
    boardId?: string;
    fromShapeId?: string;
    toShapeId?: string;
    createdAt?: string;
    updatedAt?: string;
    text?: string;
    isDeleted?: boolean;
    select?: string;
    order?: string;
    range?: string;
    rangeUnit?: string;
    offset?: string;
    limit?: string;
    prefer?: ArrowGetPreferEnum;
}

export interface ArrowPatchRequest {
    id?: string;
    boardId?: string;
    fromShapeId?: string;
    toShapeId?: string;
    createdAt?: string;
    updatedAt?: string;
    text?: string;
    isDeleted?: boolean;
    prefer?: ArrowPatchPreferEnum;
    arrow?: OdysArrow;
}

export interface ArrowPostRequest {
    select?: string;
    prefer?: ArrowPostPreferEnum;
    arrow?: OdysArrow;
}

/**
 * 
 */
export class ArrowApi extends runtime.BaseAPI {

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowDeleteRaw(requestParameters: ArrowDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.boardId !== undefined) {
            queryParameters['board_id'] = requestParameters.boardId;
        }

        if (requestParameters.fromShapeId !== undefined) {
            queryParameters['from_shape_id'] = requestParameters.fromShapeId;
        }

        if (requestParameters.toShapeId !== undefined) {
            queryParameters['to_shape_id'] = requestParameters.toShapeId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.isDeleted !== undefined) {
            queryParameters['is_deleted'] = requestParameters.isDeleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/arrow`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowDelete(requestParameters: ArrowDeleteRequest): Promise<void> {
        await this.arrowDeleteRaw(requestParameters);
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowGetRaw(requestParameters: ArrowGetRequest): Promise<runtime.ApiResponse<Array<OdysArrow>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.boardId !== undefined) {
            queryParameters['board_id'] = requestParameters.boardId;
        }

        if (requestParameters.fromShapeId !== undefined) {
            queryParameters['from_shape_id'] = requestParameters.fromShapeId;
        }

        if (requestParameters.toShapeId !== undefined) {
            queryParameters['to_shape_id'] = requestParameters.toShapeId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.isDeleted !== undefined) {
            queryParameters['is_deleted'] = requestParameters.isDeleted;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.range !== undefined && requestParameters.range !== null) {
            headerParameters['Range'] = String(requestParameters.range);
        }

        if (requestParameters.rangeUnit !== undefined && requestParameters.rangeUnit !== null) {
            headerParameters['Range-Unit'] = String(requestParameters.rangeUnit);
        }

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/arrow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OdysArrowFromJSON));
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowGet(requestParameters: ArrowGetRequest): Promise<Array<OdysArrow>> {
        const response = await this.arrowGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowPatchRaw(requestParameters: ArrowPatchRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.boardId !== undefined) {
            queryParameters['board_id'] = requestParameters.boardId;
        }

        if (requestParameters.fromShapeId !== undefined) {
            queryParameters['from_shape_id'] = requestParameters.fromShapeId;
        }

        if (requestParameters.toShapeId !== undefined) {
            queryParameters['to_shape_id'] = requestParameters.toShapeId;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.isDeleted !== undefined) {
            queryParameters['is_deleted'] = requestParameters.isDeleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/arrow`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OdysArrowToJSON(requestParameters.arrow),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowPatch(requestParameters: ArrowPatchRequest): Promise<void> {
        await this.arrowPatchRaw(requestParameters);
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowPostRaw(requestParameters: ArrowPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.prefer !== undefined && requestParameters.prefer !== null) {
            headerParameters['Prefer'] = String(requestParameters.prefer);
        }

        const response = await this.request({
            path: `/arrow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OdysArrowToJSON(requestParameters.arrow),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * An arrow connects two shapes. It must have a direction (from, to).
     */
    async arrowPost(requestParameters: ArrowPostRequest): Promise<void> {
        await this.arrowPostRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ArrowDeletePreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum ArrowGetPreferEnum {
    Countnone = 'count=none'
}
/**
    * @export
    * @enum {string}
    */
export enum ArrowPatchPreferEnum {
    Representation = 'return=representation',
    Minimal = 'return=minimal',
    None = 'return=none'
}
/**
    * @export
    * @enum {string}
    */
export enum ArrowPostPreferEnum {
    Returnrepresentation = 'return=representation',
    Returnminimal = 'return=minimal',
    Returnnone = 'return=none',
    ResolutionignoreDuplicates = 'resolution=ignore-duplicates',
    ResolutionmergeDuplicates = 'resolution=merge-duplicates'
}
