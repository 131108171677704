/* tslint:disable */
/* eslint-disable */
/**
 * PostgREST API
 * This is a dynamic API generated by PostgREST
 *
 * The version of the OpenAPI document: 10.1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Feedback about the app
 * @export
 * @interface OdysFeedback
 */
export interface OdysFeedback {
    /**
     * Unique ID
     * 
     * Note:
     * This is a Primary Key.<pk/>
     * @type {string}
     * @memberof OdysFeedback
     */
    id: string;
    /**
     * feedback content
     * @type {string}
     * @memberof OdysFeedback
     */
    text: string;
    /**
     * room where feedback was given (debugging info)
     * 
     * Note:
     * This is a Foreign Key to `room.id`.<fk table='room' column='id'/>
     * @type {string}
     * @memberof OdysFeedback
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof OdysFeedback
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OdysFeedback
     */
    updatedAt: string;
}

export function OdysFeedbackFromJSON(json: any): OdysFeedback {
    return OdysFeedbackFromJSONTyped(json, false);
}

export function OdysFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): OdysFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': json['text'],
        'roomId': !exists(json, 'room_id') ? undefined : json['room_id'],
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function OdysFeedbackToJSON(value?: OdysFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'room_id': value.roomId,
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}


